<template>
    <!-- Navbar Start -->
      <nav class="navbar" :class="bg ? 'bg-white dark:bg-slate-900 shadow dark:shadow-gray-800' : '' | scroll ? 'is-sticky' : ''" id="navbar" >
        <div class="container relative flex flex-wrap items-center justify-between">
            <router-link v-if="!navLight" class="navbar-brand md:me-8" to="/">
                <img :src="appIcon" class="h-20 inline-block dark:hidden" alt=""/>
                <img :src="appIcon" class="h-20 hidden dark:inline-block" alt=""/>
            </router-link>

            <router-link v-if="navLight" class="navbar-brand" to="/">
                <span class="inline-block dark:hidden">
                    <img :src="appIcon" class="l-dark" alt=""/>
                    <img :src="appIcon" class="l-light" alt=""/>
                </span>
                <img :src="appIcon" class="hidden dark:inline-block" alt=""/>
            </router-link>

            <div class="nav-icons flex items-center lg_992:order-2 md:ms-6">
                <!-- Navbar Button -->
                <!-- <ul v-if="!playBtn" class="list-none menu-social mb-0">
                    <li class="inline">
                        <router-link to="" class="size-8 inline-flex items-center justify-center rounded-full align-middle bg-red-500/10 hover:bg-red-500 text-red-500 hover:text-white"><i data-feather="user" class="size-4"></i></router-link>
                    </li>
                    <li class="inline">
                        <router-link to="" class="h-8 px-4 text-[12px] tracking-wider inline-flex items-center justify-center font-medium rounded-full bg-red-500 text-white uppercase">Signup</router-link>
                    </li>
                </ul> -->

                <ul v-if="playBtn" class="list-none menu-social mb-0">
                    <li class="inline">
                        <a href="" class="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center"><img :src="app" alt=""></a>
                    </li>

                    <li class="inline ms-1">
                        <a href="" class="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center"><img :src="play" alt=""></a>
                    </li>
                </ul>
                <!-- Navbar Collapse Manu Button -->
                <button type="button" class="collapse-btn inline-flex items-center ms-2 text-slate-900 dark:text-white lg_992:hidden" v-on:click="handler()">
                    <span class="sr-only">Navigation Menu</span>
                    <i class="mdi mdi-menu text-[24px]"></i>
                </button>
            </div>

            <!-- Navbar Manu -->
            <div class="navigation lg_992:order-1 lg_992:flex" id="menu-collapse" :class="toggle ? '' : 'hidden' | navCenter ? '' : ' ms-auto'">
                <ul class="navbar-nav" :class="navLight ? 'nav-light' : ''" id="navbar-navlist">
                    <li class="nav-item home active">
                        <router-link class="nav-link active" to="" v-on:click="scrollToSection('home')">Home</router-link>
                    </li>
                    <li class="nav-item download ms-0">
                        <router-link class="nav-link" to="/terms" target='_blank'>Terms & Conditions</router-link>
                    </li>
                    <li class="nav-item reviews ms-0">
                        <router-link class="nav-link" to="policy" target='_blank'>Privacy Policy</router-link>
                    </li>
                </ul>
            </div>
        </div>
      </nav>
    <!-- Navbar End -->
</template>

<script setup>
    import { ref, onMounted, onUnmounted } from 'vue';
    import feather from 'feather-icons';
    
    import appIcon from '@/assets/images/icon-512.png'
    import app from '@/assets/images/appstore.png'
    import play from '@/assets/images/playstore.png'

    defineProps({
        navLight:Boolean,
        playBtn:Boolean,
        bg:Boolean,
        navCenter:Boolean
    })

    const toggle = ref(false)
    const sections = ref([])
    const navLi = ref([])
    const current = ref("")
    const sectionTop = ref(null)

    const scroll = ref(false)

    onMounted(()=>{
        sections.value = document.querySelectorAll("section")
        navLi.value = document.querySelectorAll("nav .container .navigation ul li")

        window.addEventListener('scroll', onscroll)
        window.addEventListener('scroll', handleScroll);

        feather.replace();
    })

    onUnmounted(() => {
        window.removeEventListener('scroll', onscroll);
        window.removeEventListener('scroll', handleScroll);
    })


    function onscroll() {
        sections.value.forEach((section) => {
        
            sectionTop.value = section.offsetTop
            if (pageYOffset >= sectionTop.value - 60) {
                current.value = section.getAttribute("id")
            }
        })
        navLi.value.forEach((li) => {
            if (li.classList.contains(current.value)) {
                li.classList.add("active")
            } else {
                li.classList.remove("active")
            }
        })
    }

    function scrollToSection(sectionId) {
        const element = document.getElementById(sectionId);
        if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleScroll = () => {
        if (window.scrollY >= 50) {
            scroll.value = true
        } else {
            scroll.value = false
        }
    }

    const handler = () => {
        toggle.value = !toggle.value
    }

</script>


