<template>
        <!-- Start Footer -->
        <footer class="py-8 bg-slate-800 dark:bg-gray-900">
            <div class="container">
                <div class="grid md:grid-cols-12 items-center">
                    <div class="md:col-span-3">
                    </div>

                    <div class="md:col-span-5 md:mt-0 mt-8">
                        <div class="text-center">
                            <p class="text-gray-400">© {{year}} Bored? <i class="mdi mdi-heart text-red-700"></i></p>
                        </div>
                    </div>

                    <div class="md:col-span-4 md:mt-0 mt-8">
                        <ul class="list-none foot-icon ltr:md:text-right rtl:md:text-left text-center">
                            <li class="inline"><a href="#" target="_blank" class="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-700 hover:border-red-500 rounded-md text-slate-300 hover:text-white hover:bg-red-500"><i data-feather="instagram" class="h-4 w-4 align-middle" title="instagram"></i></a></li>
                            <li class="inline"><a href="#" class="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-700 hover:border-red-500 rounded-md text-slate-300 hover:text-white hover:bg-red-500"><i data-feather="mail" class="h-4 w-4 align-middle" title="email"></i></a></li>
                        </ul><!--end icon-->
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->
        </footer><!--end footer-->
        <!-- End Footer -->
</template>

<script setup>
import { ref } from 'vue';
    import logo from '@/assets/images/logo-light.png'

    const year = ref(new Date().getFullYear());

</script>
