<template>
        <div class="container relative">
            <div class="grid md:grid-cols-6 grid-cols-2 justify-center gap-[30px]">
                <div v-for="(item, index) in images" :key="index" class="mx-auto py-4">
                    <img :src="item" class="h-6" alt="">
                </div>
            </div><!--end grid-->
        </div><!--end container-->
</template>

<script setup>

    import { ref } from 'vue'
    import partner1 from '@/assets/images/client/amazon.svg'
    import partner2 from '@/assets/images/client/google.svg'
    import partner3 from '@/assets/images/client/lenovo.svg'
    import partner4 from '@/assets/images/client/paypal.svg'
    import partner5 from '@/assets/images/client/shopify.svg'
    import partner6 from '@/assets/images/client/spotify.svg'

    const images = ref([
        partner1,partner2,partner3,partner4,partner5,partner6
    ])

</script>
