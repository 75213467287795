<template>
    <div class="legal-document cookies-policy">
      <div class="header">
        <h1 class="text-3xl font-bold mb-4">Cookies Policy</h1>
        <p class="text-gray-600 italic mb-8">Last updated: November 08, 2024</p>
      </div>
  
      <div class="content space-y-8">
        <section>
          <h2 class="text-2xl font-semibold mb-4">1. What Are Cookies</h2>
          <p class="mb-4">
            Cookies are small text files that are stored on your device when you use our App.
            They help us provide and improve our services by remembering your preferences
            and understanding how you use our App.
          </p>
        </section>
  
        <section>
          <h2 class="text-2xl font-semibold mb-4">2. Types of Cookies We Use</h2>
          <div class="space-y-4">
            <div>
              <h3 class="text-xl font-medium mb-2">2.1. Essential Cookies</h3>
              <p>Required for the App to function properly. Cannot be disabled.</p>
              <ul class="list-disc ml-6 space-y-2">
                <li>Authentication</li>
                <li>Security</li>
                <li>Basic functionality</li>
              </ul>
            </div>
  
            <div>
              <h3 class="text-xl font-medium mb-2">2.2. Preference Cookies</h3>
              <p>Remember your settings and preferences.</p>
              <ul class="list-disc ml-6 space-y-2">
                <li>Language preferences</li>
                <li>Theme settings</li>
                <li>Volume settings</li>
              </ul>
            </div>
  
            <div>
              <h3 class="text-xl font-medium mb-2">2.3. Analytics Cookies</h3>
              <p>Help us understand how users interact with our App.</p>
              <ul class="list-disc ml-6 space-y-2">
                <li>Usage patterns</li>
                <li>Performance data</li>
                <li>Error tracking</li>
              </ul>
            </div>
          </div>
        </section>
  
        <section>
          <h2 class="text-2xl font-semibold mb-4">3. Cookie Management</h2>
          <div class="space-y-4">
            <h3 class="text-xl font-medium mb-2">3.1. Managing Cookie Settings</h3>
            <p>You can manage cookie preferences through:</p>
            <ul class="list-disc ml-6 space-y-2">
              <li>App settings</li>
              <li>Device settings</li>
              <li>Browser settings (for web version)</li>
            </ul>
          </div>
        </section>
  
        <section>
          <h2 class="text-2xl font-semibold mb-4">4. Third-Party Cookies</h2>
          <p class="mb-4">
            We may use third-party services that set cookies, including:
          </p>
          <ul class="list-disc ml-6 space-y-2">
            <li>Analytics services</li>
            <li>Advertisement providers</li>
            <li>Social media integration</li>
          </ul>
        </section>
  
        <section>
          <h2 class="text-2xl font-semibold mb-4">5. Updates to This Policy</h2>
          <p class="mb-4">
            We may update this Cookies Policy from time to time. We will notify you of any
            changes by posting the new policy on this page and updating the "Last updated" date.
          </p>
        </section>
      </div>
    </div>
  </template>