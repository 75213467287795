<template>
    <div>
        <Navbar :navLight="true" :playBtn="true"/>

         <!-- Start Hero -->
        <section class="relative pt-24 xl:pb-[420px] md:pb-80 pb-36 items-center overflow-hidden bg-red-500 bg-[url('../../assets/images/bg2.png')] bg-center bg-no-repeat bg-cover" id="home">
            <div class="container relative">
                <div class="grid grid-cols-1 mt-10 text-center">
                    <h3 class="font-bold lg:leading-normal leading-normal text-4xl lg:text-6xl mb-5 text-white">Get started with <br> Appever Template.</h3>

                    <p class="text-white/70 text-lg max-w-xl mx-auto">Gain valuable insights into user behavior and drive data-informed decision-making with our revolutionary platform.</p>
                
                    <div class="mt-6">
                        <router-link to="" class="py-1.5 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-white text-slate-900 rounded-md">Download Now</router-link>
                        <router-link to="" @click="open = !open" class="size-9 inline-flex items-center justify-center align-middle rounded-full bg-white text-slate-900 m-1 lightbox"><i data-feather="video" class="size-4"></i></router-link><span class="font-semibold text-white ms-1 align-middle">Watch Now</span>
                    </div>
                </div><!--end grid-->
            </div><!--end container-->
        </section><!--end section-->
        <div class="relative">
            <div class="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden text-white dark:text-slate-900">
                <svg class="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>
        <!-- End Hero -->

        <!-- START -->
        <section class="relative mt-8">
            <div class="container relative">
                <div class="grid grid-cols-1 justify-center">
                    <div class="relative z-2 duration-500 xl:-mt-[400px] lg:-mt-[320px] md:-mt-[350px] -mt-[140px] m-0">
                        <img :src="hero" class="lg:px-10" alt="">
                    </div>
                </div>
            </div>
        </section>
        <!-- End -->

        <!-- Start -->
        <section class="relative md:py-24 py-16" id="features">
            <div class="container relative md:mb-24 mb-16">
                <AboutOne/>
            </div><!--end container-->

            <FeatureTwo/>

            <div class="container relative md:mt-24 mt-16">
                <AboutTwo/>
            </div><!--end container-->
        </section><!--end section-->
        <!-- End -->

        <!-- Start -->
        <section class="relative md:py-24 py-16 bg-slate-50/50 dark:bg-slate-800/20" id="screenshot">
            <Screenshort/>
        </section><!--end section-->
        <!-- End -->

        <!-- Start -->
        <section class="relative overflow-hidden md:py-24 py-16" id="faqs">
            <Faq/>
        </section><!--end section-->
        <!-- End Section-->

        <!-- Start -->
        <section class="relative md:py-24 py-16 bg-slate-50/50 dark:bg-slate-800/20" id="download">
            <Download/>
        </section><!--end section-->
        <!-- End -->

        <!-- Start -->
        <section class="relative md:py-24 py-16" id="reviews">
            <div class="container relative">
                <div class="grid grid-cols-1 pb-6 text-center">
                    <h6 class="text-red-500 uppercase text-sm font-bold tracking-wider mb-3">Reviews</h6>
                    <h4 class="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-bold">10k+ Customers Trust Us</h4>

                    <p class="text-slate-400 max-w-xl mx-auto">Unleash the power of our platform with a multitude of powerful features, empowering you to achieve your goals.</p>
                </div><!--end grid-->

                <Client/>
            </div><!--end container-->
        </section><!--end section-->
        <!-- End Section-->

        <!-- Start -->
        <section class="relative md:py-24 py-16 bg-slate-50/50 dark:bg-slate-800/20" id="contact">
            <Contact/>
        </section><!--end section-->
        <!-- End -->

        <!-- Business Partner -->
        <section class="py-6 border-t border-b border-gray-100 dark:border-gray-800">
            <Partner/>
        </section><!--end section-->
        <!-- Business Partner -->

        <FooterOne/>

        <ScrollToTop/>

        <Switcher/>

        <div class="fixed w-full h-[100vh] top-0 left-0 z-999" :class="open ? '' : 'hidden'" style="background-color: rgba(0, 0, 0, 0.5);">
            <div class="w-full h-full flex justify-center items-center">
                <div class="relative px-4 w-full max-w-2xl h-auto">
                    <button class="absolute right-0 -top-4 bg-slate-600 p-[2px] rounded z-[9999]" @click="open = !open"><i data-feather="x" class="text-slate-300"></i></button>
                    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div class="flex justify-between items-start p-0">
                            <iframe width="750" height="400" src="https://www.youtube.com/embed/yba7hPeTSjk?playlist=yba7hPeTSjk&loop=1"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
    import { ref } from 'vue';
    import Navbar from '@/components/navbar.vue';
    import hero from '@/assets/images/phone/3-phone-full.png'
    import AboutOne from '@/components/about-one.vue';
    import AboutTwo from '@/components/about-two.vue';
    import FeatureTwo from '@/components/feature-two.vue';
    import Screenshort from '@/components/screenshort.vue';
    import Faq from '@/components/faq.vue';
    import Download from '@/components/download.vue';
    import Client from '@/components/client.vue';
    import Partner from '@/components/partner.vue';
    import Contact from '@/components/contact.vue';
    import FooterOne from '@/components/footer-one.vue';
    import ScrollToTop from '@/components/scroll-to-top.vue';
    import Switcher from '@/components/switcher.vue';

    const open = ref(false)

</script>

