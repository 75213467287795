<template>
    <div class="grid grid-cols-1 mt-6">
        <div class="tiny-three-item wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
            <div v-for="(item, index) in clientData" :key="index" class="tiny-slide text-center">
                <div class="cursor-e-resize">
                    <div class="content relative rounded shadow dark:shadow-gray-800 m-2 p-6 bg-white dark:bg-slate-900 before:content-[''] before:absolute before:start-1/2 before:-bottom-[4px] before:box-border before:border-8 before:rotate-[45deg] before:border-t-transparent before:border-e-white dark:before:border-e-slate-900 before:border-b-white dark:before:border-b-slate-900 before:border-s-transparent before:shadow-testi dark:before:shadow-gray-700 before:origin-top-left">
                        <i class="mdi mdi-format-quote-open mdi-48px text-red-500"></i>
                        <p class="text-slate-400">{{item.desc}}</p>
                        <ul class="list-none mb-0 text-amber-400 mt-3">
                            <li class="inline"><i class="mdi mdi-star"></i></li>
                            <li class="inline"><i class="mdi mdi-star"></i></li>
                            <li class="inline"><i class="mdi mdi-star"></i></li>
                            <li class="inline"><i class="mdi mdi-star"></i></li>
                            <li class="inline"><i class="mdi mdi-star"></i></li>
                        </ul>
                    </div>
                    
                    <div class="text-center mt-5">
                        <img :src="item.image" class="size-14 rounded-full shadow-md mx-auto" alt="">
                        <h6 class="mt-2 font-semibold">{{item.name}}</h6>
                        <span class="text-slate-400 text-sm">{{item.position}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div><!--end grid-->
</template>

<script setup>

    import { onMounted, ref } from 'vue'
    import { tns } from 'tiny-slider'
    import client1 from '@/assets/images/client/01.jpg'
    import client2 from '@/assets/images/client/02.jpg'
    import client3 from '@/assets/images/client/03.jpg'
    import client4 from '@/assets/images/client/04.jpg'
    import client5 from '@/assets/images/client/05.jpg'
    import client6 from '@/assets/images/client/06.jpg'

    const clientData = ref([
        {
            image:client1,
            name: 'Calvin Carlo',
            position:'Manager',
            desc:'" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. "'
        },
        {
            image:client2,
            name: 'Christa Smith',
            position:'Manager',
            desc:`" The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century. "`
        },
        {
            image:client3,
            name: 'Jemina CLone',
            position:'Manager',
            desc:`" One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others. "`
        },
        {
            image:client4,
            name: 'Smith Vodka',
            position:'Manager',
            desc:'" Thus, Lorem Ipsum has only limited suitability as a visual filler for German texts. "'
        },
        {
            image:client5,
            name: 'Cristino Murfi',
            position:'Manager',
            desc:`" There is now an abundance of readable dummy texts. These are usually used when a text is required. "`
        },
        {
            image:client6,
            name: 'Cristino Murfi',
            position:'Manager',
            desc:`" According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero. "`
        },
    ])

    onMounted(() => {
    tns({
        container: '.tiny-three-item',
        controls: false,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        speed: 400,
        gutter: 12,
        responsive: {
            992: {
                items: 3
            },

            767: {
                items: 2
            },

            320: {
                items: 1
            },
        },
    });
});

</script>
