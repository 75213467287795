<template>
    <div class="legal-document privacy-policy">
      <div class="header">
        <h1 class="text-3xl font-bold mb-4">Privacy Policy</h1>
        <p class="text-gray-600 italic mb-8">Last updated: November 08, 2024</p>
      </div>
  
      <div class="content space-y-8">
        <section>
          <h2 class="text-2xl font-semibold mb-4">1. Information We Collect</h2>
          <div class="mb-4">
            <h3 class="text-xl font-medium mb-2">1.1. Personal Information</h3>
            <ul class="list-disc ml-6 space-y-2">
              <li>Phone number</li>
              <li>Profile information (username, display name)</li>
              <li>Profile picture</li>
              <li>Bio and other optional information</li>
            </ul>
          </div>
          <div class="mb-4">
            <h3 class="text-xl font-medium mb-2">1.2. Usage Information</h3>
            <ul class="list-disc ml-6 space-y-2">
              <li>Device information</li>
              <li>Log data</li>
              <li>Usage statistics</li>
              <li>Transaction history</li>
            </ul>
          </div>
        </section>
  
        <section>
          <h2 class="text-2xl font-semibold mb-4">2. How We Use Your Information</h2>
          <ul class="list-disc ml-6 space-y-2">
            <li>Provide and improve our services</li>
            <li>Personalize your experience</li>
            <li>Process transactions</li>
            <li>Send notifications and updates</li>
            <li>Prevent fraud and abuse</li>
            <li>Comply with legal obligations</li>
          </ul>
        </section>
  
        <section>
          <h2 class="text-2xl font-semibold mb-4">3. Information Sharing</h2>
          <div class="mb-4">
            <h3 class="text-xl font-medium mb-2">3.1. We Share With</h3>
            <ul class="list-disc ml-6 space-y-2">
              <li>Service providers</li>
              <li>Legal authorities when required</li>
              <li>Other users (based on your privacy settings)</li>
            </ul>
          </div>
        </section>
  
        <section>
          <h2 class="text-2xl font-semibold mb-4">4. Data Security</h2>
          <p class="mb-4">
            We implement appropriate security measures to protect your information, including:
          </p>
          <ul class="list-disc ml-6 space-y-2">
            <li>Encryption of sensitive data</li>
            <li>Regular security assessments</li>
            <li>Access controls</li>
            <li>Secure data storage</li>
          </ul>
        </section>
  
        <section>
          <h2 class="text-2xl font-semibold mb-4">5. Your Rights</h2>
          <ul class="list-disc ml-6 space-y-2">
            <li>Access your data</li>
            <li>Request data correction</li>
            <li>Delete your account</li>
            <li>Object to processing</li>
            <li>Data portability</li>
          </ul>
        </section>
      </div>
    </div>
  </template>