<template>
    <div class="container relative">
        <div class="grid grid-cols-1 pb-6 text-center wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
            <h6 class="text-red-500 uppercase text-sm font-bold tracking-wider mb-3">Screenshots</h6>
            <h4 class="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-bold">Simple & Beautiful Interface</h4>

            <p class="text-slate-400 max-w-xl mx-auto">Unleash the power of our platform with a multitude of powerful features, empowering you to achieve your goals.</p>
        </div><!--end grid-->

        <div class="grid grid-cols-1 mt-6 relative">
            <div class="tiny-four-item wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div v-for="(item, indeximg) in images" :key="indeximg" class="tiny-slide">
                    <a class="lightbox" @click="showLightbox(indeximg)"><img :src="item" alt=""></a>
                </div>
            </div>
        </div>
    </div><!--end container-->
</template>

<script setup>
    import { onMounted, ref } from 'vue';
    import { tns } from 'tiny-slider';
    
    import VueEasyLightbox from 'vue-easy-lightbox';

    import phone1 from '@/assets/images/phone/1.png'
    import phone2 from '@/assets/images/phone/2.png'
    import phone3 from '@/assets/images/phone/3.png'
    import phone4 from '@/assets/images/phone/4.png'
    import phone5 from '@/assets/images/phone/5.png'
    import phone6 from '@/assets/images/phone/6.png'
    import phone7 from '@/assets/images/phone/7.png'
    import phone8 from '@/assets/images/phone/8.png'

    const images = ref([
        phone1,phone2,phone3,phone4,phone5,phone6,phone7,phone8
    ])

    const visibleRef = ref(false);
    const imgsRef = ref([]);
    const indexRef = ref(0);

    onMounted(()=>{
        imgsRef.value = images.value;
        tns({
        container: '.tiny-four-item',
        controls: true,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        controlsText: ['<i class="mdi mdi-chevron-left "></i>', '<i class="mdi mdi-chevron-right"></i>'],
        nav: false,
        speed: 400,
        gutter: 0,
        responsive: {
            992: {
                items: 4
            },

            767: {
                items: 3
            },

            425: {
                items: 1
            },
        },
    });
    })

    const showLightbox = (index) =>{
        indexRef.value = index; // Set the clicked image index
        visibleRef.value = true;
    }

    const onHide = () =>{
        visibleRef.value = false
    }

</script>

