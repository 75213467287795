<template>
    <div class="container relative">
        <div class="grid md:grid-cols-12 grid-cols-1 items-center gap-6">
            <div class="lg:col-span-5 md:col-span-6">
                <div class="pt-6 px-6 rounded-2xl bg-red-500/5 dark:bg-red-500/10 shadow shadow-red-500/20">
                    <img :src="phone" alt="">
                </div>
            </div><!--end grid-->

            <div class="lg:col-span-7 md:col-span-6">
                <div class="lg:ms-10">
                    <h6 class="text-red-500 uppercase text-sm font-bold tracking-wider mb-3">Bored? Join us!</h6>
                    <h4 class="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-bold">Fun &<br> Earn</h4>
                    <p class="text-slate-400 max-w-xl mb-0">Social network with mini games. Play to Earn.</p>
                    
                    <div class="my-6">
                        <router-link to=""><img :src="app" class="h-12 inline-block m-1" alt=""/></router-link>
                        <router-link to=""><img :src="play" class="h-12 inline-block m-1" alt=""/></router-link>
                    </div>

                    
                </div>
            </div>
        </div><!--end gird-->
    </div><!--end container-->
</template>

<script setup>
import * as feather from 'feather-icons'
import phone from '@/assets/images/phone/half-3.png'
import app from '@/assets/images/app.png'
import play from '@/assets/images/play.png'
import { onMounted } from 'vue';

onMounted(()=>{
    feather.replace()
})

</script>
