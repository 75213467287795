<template>
    <div class="legal-document terms-conditions">
      <div class="header">
        <h1 class="text-3xl font-bold mb-4">Terms and Conditions</h1>
        <p class="text-gray-600 italic mb-8">Last updated: November 08, 2024</p>
      </div>
  
      <div class="content space-y-8">
        <section>
          <h2 class="text-2xl font-semibold mb-4">1. Agreement to Terms</h2>
          <p class="mb-4">
            By accessing and using this application ("App"), you agree to be bound by these Terms and Conditions ("Terms"). 
            If you disagree with any part of these terms, you may not access the App.
          </p>
        </section>
  
        <section>
          <h2 class="text-2xl font-semibold mb-4">2. Account Registration and Security</h2>
          <div class="mb-4">
            <h3 class="text-xl font-medium mb-2">2.1. Registration Requirements</h3>
            <ul class="list-disc ml-6 space-y-2">
              <li>Must be at least 13 years old</li>
              <li>Valid phone number required</li>
              <li>Accurate profile information required</li>
              <li>One account per person</li>
            </ul>
          </div>
          <div class="mb-4">
            <h3 class="text-xl font-medium mb-2">2.2. Account Security</h3>
            <ul class="list-disc ml-6 space-y-2">
              <li>Secure password required</li>
              <li>Regular security updates recommended</li>
              <li>Immediate notification of unauthorized access required</li>
              <li>Account sharing prohibited</li>
            </ul>
          </div>
        </section>
  
        <section>
          <h2 class="text-2xl font-semibold mb-4">3. User Content</h2>
          <div class="mb-4">
            <h3 class="text-xl font-medium mb-2">3.1. Content Rules</h3>
            <ul class="list-disc ml-6 space-y-2">
              <li>Users retain ownership of their content</li>
              <li>Content must not violate any laws</li>
              <li>Prohibited content includes: hate speech, violence, explicit material</li>
              <li>Right to remove content that violates terms</li>
            </ul>
          </div>
        </section>
  
        <section>
          <h2 class="text-2xl font-semibold mb-4">4. Virtual Currency and Transactions</h2>
          <div class="mb-4">
            <h3 class="text-xl font-medium mb-2">4.1. Coins</h3>
            <ul class="list-disc ml-6 space-y-2">
              <li>Virtual currency has no real-world value</li>
              <li>Purchases are non-refundable</li>
              <li>Cannot be transferred between accounts</li>
              <li>May expire based on promotional terms</li>
            </ul>
          </div>
        </section>
  
        <section>
          <h2 class="text-2xl font-semibold mb-4">5. Lucky Draws and Challenges</h2>
          <div class="mb-4">
            <ul class="list-disc ml-6 space-y-2">
              <li>Subject to local laws and regulations</li>
              <li>Winners responsible for applicable taxes</li>
              <li>Right to verify eligibility</li>
              <li>Decisions are final</li>
            </ul>
          </div>
        </section>
  
        <section>
          <h2 class="text-2xl font-semibold mb-4">6. Termination</h2>
          <p class="mb-4">
            We reserve the right to suspend or terminate accounts that violate these terms,
            with or without notice. Upon termination, access to services will be revoked.
          </p>
        </section>
      </div>
    </div>
  </template>