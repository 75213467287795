<template>
    <div class="container relative">
        <div class="grid grid-cols-1 pb-6 text-center">
            <h6 class="text-red-500 uppercase text-sm font-bold tracking-wider mb-3">Features</h6>
            <h4 class="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-bold">Discover Powerful Features</h4>

            <p class="text-slate-400 max-w-xl mx-auto">Unleash the power of our platform with a multitude of powerful features, empowering you to achieve your goals.</p>
        </div><!--end grid-->

        <div class="grid lg:grid-cols-12 md:grid-cols-12 grid-cols-1 mt-6 gap-6 items-center">
            <div class="lg:col-span-4 md:col-span-6 lg:order-1 order-2">
                <div class="grid grid-cols-1 gap-6">
                    <div v-for="(item, index) in feature1" :key="index" class="group flex duration-500 xl:p-3">
                        <div class="flex md:order-2 order-1 align-middle justify-center items-center size-16 mt-1 bg-red-500/5 dark:bg-red-500/10 group-hover:bg-red-500 group-hover:text-white text-red-500 rounded-2xl text-2xl shadow-sm dark:shadow-gray-800 duration-500">
                            <i :data-feather="item.icon" class="size-5"></i>
                        </div>
                        <div class="flex-1 md:order-1 order-2 md:text-end md:me-4 md:ms-0 ms-4">
                            <h4 class="mb-0 text-lg font-semibold">{{item.title}}</h4>
                            <p class="text-slate-400 mt-3">{{item.desc}}</p>
                        </div>
                    </div>
                    <!--end feature-->
                </div>
            </div>

            <div class="lg:col-span-4 md:col-span-12 lg:mx-4 lg:order-2 order-1">
                <img :src="phone" class="mx-auto" alt="">
            </div>

            <div class="lg:col-span-4 md:col-span-6 order-3">
                <div class="grid grid-cols-1 gap-6">
                    <div v-for="(item, index) in feature2" :key="index" class="group flex duration-500 xl:p-3">
                        <div class="flex align-middle justify-center items-center size-16 mt-1 bg-red-500/5 dark:bg-red-500/10 group-hover:bg-red-500 group-hover:text-white text-red-500 rounded-2xl text-2xl shadow-sm dark:shadow-gray-800 duration-500">
                            <i :data-feather="item.icon" class="size-5"></i>
                        </div>
                        <div class="flex-1 ms-4">
                            <h4 class="mb-0 text-lg font-semibold">{{item.title}}</h4>
                            <p class="text-slate-400 mt-3">{{item.desc}}</p>
                        </div>
                    </div>
                    <!--end feature-->
                </div>
            </div>
        </div><!--end grid-->
    </div><!--end container-->
</template>

<script setup>

    import phone from '@/assets/images/phone/1.png'
    import { ref } from 'vue';

    const feature1 = ref([
        {
            icon:'monitor',
            title:'Use On Any Device',
            desc:'One disadvantage of Lorum Ipsum is that in Latin frequently than others'
        },
        {
            icon:'feather',
            title:'Feather Icons',
            desc:'One disadvantage of Lorum Ipsum is that in Latin frequently than others'
        },
        {
            icon:'eye',
            title:'Retina Ready',
            desc:'One disadvantage of Lorum Ipsum is that in Latin frequently than others'
        },
    ])
    const feature2 = ref([
        {
            icon:'user-check',
            title:'W3c Valid Code',
            desc:'One disadvantage of Lorum Ipsum is that in Latin frequently than others'
        },
        {
            icon:'smartphone',
            title:'Fully Responsive',
            desc:'One disadvantage of Lorum Ipsum is that in Latin frequently than others'
        },
        {
            icon:'heart',
            title:'Browser Compatibility',
            desc:'One disadvantage of Lorum Ipsum is that in Latin frequently than others'
        },
    ])

</script>

