import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/tailwind.css'
import '@/assets/libs/@mdi/font/css/materialdesignicons.min.css'

import 'animate.css'; // Import animate.css
// import WOW from 'wowjs'; // Import Wow.js


createApp(App).use(router).mount('#app')


