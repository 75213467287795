<template>
    <div class="container relative">
        <div class="grid grid-cols-1 pb-6 text-center">
            <h6 class="text-red-500 uppercase text-sm font-bold tracking-wider mb-3">Bored? Join us!</h6>
            <h4 class="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-bold">Fun & Earn</h4>

            <p class="text-slate-400 max-w-xl mx-auto">Social network with mini games. Play to Earn.</p>
            <div class="my-6">
                <router-link to=""><img :src="app" class="h-12 inline-block m-1" alt=""/></router-link>
                <router-link to=""><img :src="play" class="h-12 inline-block m-1" alt=""/></router-link>
            </div>
        </div><!--end grid-->
       

        <div class="grid grid-cols-1 mt-6 relative">
            <div class="tiny-four-item">
                <div v-for="(item, indeximg) in images" :key="indeximg" class="tiny-slide">
                    <a class="lightbox" @click="showLightbox(indeximg)"><img :src="item" alt=""></a>
                </div>
            </div>
        </div>
        <vue-easy-lightbox :visible="visibleRef" :imgs="imgsRef" :index="indexRef" @hide="onHide"></vue-easy-lightbox>
    </div><!--end container-->
</template>

<script setup>
    import { onMounted, ref } from 'vue';
    import { tns } from 'tiny-slider';
    
    import VueEasyLightbox from 'vue-easy-lightbox';
    import app from '@/assets/images/app.png'
    import play from '@/assets/images/play.png'

    import phone1 from '@/assets/images/sample/1.png'
    import phone2 from '@/assets/images/sample/2.png'
    import phone3 from '@/assets/images/sample/3.png'
    import phone4 from '@/assets/images/sample/4.png'
    import phone5 from '@/assets/images/sample/5.png'

    const images = ref([
        phone1,phone2,phone3,phone4,phone5
    ])

    const visibleRef = ref(false);
    const imgsRef = ref([]);
    const indexRef = ref(0);

    onMounted(()=>{
        imgsRef.value = images.value;
        tns({
        container: '.tiny-four-item',
        controls: true,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        controlsText: ['<i class="mdi mdi-chevron-left "></i>', '<i class="mdi mdi-chevron-right"></i>'],
        nav: false,
        speed: 400,
        gutter: 0,
        responsive: {
            992: {
                items: 4
            },

            767: {
                items: 3
            },

            425: {
                items: 1
            },
        },
    });
    })

    const showLightbox = (index) =>{
        indexRef.value = index; // Set the clicked image index
        visibleRef.value = true;
    }

    const onHide = () =>{
        visibleRef.value = false
    }

</script>
