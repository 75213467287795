import IndexFive from '@/pages/index-five.vue'
import IndexFour from '@/pages/index-four.vue'
import IndexOne from '@/pages/index-one.vue'
import IndexSix from '@/pages/index-six.vue'
import IndexThree from '@/pages/index-three.vue'
import IndexTwo from '@/pages/index-two.vue'
import Terms from '@/pages/terms.vue'
import Policy from '@/pages/policy.vue'
import Cookies from '@/pages/cookies.vue'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index-one',
    component: IndexOne
  },
  {
    path: '/terms',
    name: 'terms',
    component: Terms
  },
  {
    path: '/policy',
    name: 'policy',
    component: Policy
  },
  {
    path: '/cookies',
    name: 'cookies',
    component: Cookies
  },
  {
    path: '/index-two',
    name: 'index-two',
    component: IndexTwo
  },
  {
    path: '/index-three',
    name: 'index-three',
    component: IndexThree
  },
  {
    path: '/index-four',
    name: 'index-four',
    component: IndexFour
  },
  {
    path: '/index-five',
    name: 'index-five',
    component: IndexFive
  },
  {
    path: '/index-six',
    name: 'index-six',
    component: IndexSix
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
