<template>
   <!-- Back to top -->
        <a href="#" id="back-to-top" class="back-to-top fixed text-lg rounded-full z-10 bottom-5 end-5 h-9 w-9 text-center bg-red-500 text-white leading-9" :class="scroll ? 'block' : 'hidden'"><i class="mdi mdi-arrow-up"></i></a>
    <!-- Back to top -->
</template>

<script setup>
    import { ref, onMounted, onUnmounted } from 'vue';

    onMounted(() => {
        window.addEventListener('scroll', handleScroll);
    });

    onUnmounted(() => {
        window.removeEventListener('scroll', handleScroll);
    });

    const scroll = ref(false)

    const handleScroll = () =>{

        if(window.scrollY > 100){
            scroll.value = true
        }else{
            scroll.value = false
        }
    }

</script>
