<template>
    <div class="container relative">
          <div class="grid grid-cols-1 pb-6 text-center">
              <h6 class="text-red-500 uppercase text-sm font-bold tracking-wider mb-3">Features</h6>
              <h4 class="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-bold">Discover Powerful Features</h4>

              <p class="text-slate-400 max-w-xl mx-auto">Unleash the power of our platform with a multitude of powerful features, empowering you to achieve your goals.</p>
          </div><!--end grid-->

          <div class="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
              <div v-for="(item, index) in data" :key="index" class="p-6 transition duration-500 rounded-3xl" :class="item.active ? 'shadow-xl shadow-slate-100 dark:shadow-slate-800' : 'hover:shadow-xl hover:shadow-slate-100 dark:hover:shadow-slate-800'">
                  <div class="size-16 bg-red-500/5 text-red-500 rounded-2xl flex align-middle justify-center items-center shadow-sm">
                      <i :data-feather="item.icon" class="size-5"></i>
                  </div>

                  <div class="content mt-7">
                      <a href="" class="text-lg hover:text-red-500 dark:text-white dark:hover:text-red-500 transition-all duration-500 ease-in-out font-semibold">{{item.title}}</a>
                      <p class="text-slate-400 mt-3">{{item.desc}}</p>
                      
                      <div class="mt-3">
                          <router-link to="" class="hover:text-red-500 dark:hover:text-red-500 after:bg-red-500 dark:text-white transition duration-500">Read More <i class="mdi mdi-arrow-right"></i></router-link>
                      </div>
                  </div>
              </div><!--end content-->
          </div>
      </div><!--end container-->
</template>

<script setup>
import { ref } from 'vue';


const data = ref([
    {
        icon:'maximize',
        title:'Fully functional',
        desc:'The phrasal sequence of the is now so that many campaign and benefit',
        active:false
    },
    {
        icon:'pie-chart',
        title:'Secure Data',
        desc:'The phrasal sequence of the is now so that many campaign and benefit',
        active:true
    },
    {
        icon:'navigation-2',
        title:'Track Location',
        desc:'The phrasal sequence of the is now so that many campaign and benefit',
        active:false
    },
    {
        icon:'database',
        title:'Data Analyse',
        desc:'The phrasal sequence of the is now so that many campaign and benefit',
        active:true
    },
])

</script>
